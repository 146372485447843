<template>
  <div class="edit-view">
    <page-title>
      <el-button @click="$router.go(-1)"
                 :disabled="saving">取消</el-button>
      <el-button @click="onDelete"
                 :disabled="saving"
                 type="danger"
                 v-if="formData.id"> 删除 </el-button>
      <el-button @click="onSave"
                 :loading="saving"
                 type="primary">保存</el-button>
    </page-title>
    <div class="edit-view__content-wrapper">
      <div class="edit-view__content-section">
        <el-form :model="formData"
                 :rules="rules"
                 ref="form"
                 label-width="108px"
                 label-position="right"
                 size="small"
                 style="max-width: 700px">
          <el-form-item label="项目"
                        prop="projectId">
            <project-select v-model="formData.projectId"
                            @input="changeProject">
            </project-select>
          </el-form-item>
          <el-form-item prop="name"
                        label="活动名称">
            <el-input v-model="formData.name"></el-input>
          </el-form-item>
          <el-form-item label="活动类型"
                        prop="compositetype">
           <el-select v-model="formData.compositetype" @change="changeType3" placeholder="请选择类型">
               <el-option
                 v-for="item in typeOptions"
                 :key="item.value"
                 :label="item.label"
                 :value="item.value">
               </el-option>
             </el-select>
          </el-form-item>

          <el-form-item prop="name" v-if="inputStatus"
                        label="分解置换数量" >
            <el-input v-model="formData.decomposenum"></el-input>
          </el-form-item>

          <el-form-item prop="pic"
                        label="图片">
            <single-upload v-model="formData.pic"></single-upload>
          </el-form-item>
          <el-form-item prop="type"
                        label="兑换模式">
            <el-radio-group v-model="formData.type">
              <el-radio label="ONE_TO_MANY"
                       >单一集齐</el-radio>
              <el-radio label="MANY_TO_MANY"
                        >全部集齐</el-radio>
              <el-radio label="ANY_TO_ONE"
                        >指定数量</el-radio>
<!--              <el-radio label="SOME_TO_ANY"-->
<!--                        :disabled="!canEdit">全平台匹配</el-radio>-->
            </el-radio-group>
          </el-form-item>
          <el-form-item prop="needCollectedNum"
                        v-if="formData.type == 'SOME_TO_ANY'"
                        label="任意数量">
            <el-input-number type="number"
                             placeholder="任意数量"
                             v-model="formData.needCollectedNum"
                             :min="1"></el-input-number>
          </el-form-item>
          <el-form-item prop="startTime"
                        label="活动开始时间">
            <el-date-picker v-model="formData.startTime"
                            type="datetime"
                            value-format="yyyy-MM-dd HH:mm:ss"
                            placeholder="选择日期时间">
            </el-date-picker>
          </el-form-item>
            <el-form-item prop="endTime"
                          label="活动结束时间">
                <el-date-picker v-model="formData.endTime"
                                type="datetime"
                                value-format="yyyy-MM-dd HH:mm:ss"
                                placeholder="选择日期时间">
                </el-date-picker>
            </el-form-item>
          <el-form :model="{activities}"
                   ref="formref"
                   label-position="right"
                   label-width="100px"
                   size="small">
            <el-form-item v-for="(item, index) in activities"
                          :label="item.mustNeedCollected ? '兑换藏品':'持有条件' + (index+1)"
                          :key="item.key"
                          :prop="`activities[${index}].collectionId`"
                          :rules="{
                        required: true, message: '藏品不能为空', trigger: 'change'
                        }">
              <el-row :gutter="24">
                <el-col :span="10">
                  <el-select v-model="item.collectionId"
                             filterable
                             :filter-method="filterCollection"
                             style="width: 100%"
                             @change="changCollection(item,index,activities)"
                             >
                    <el-option v-for="row in filterOptions"
                               :label="row.name"
                               :value="row.id"
                               :key="row.id">
                      <span style="float: left">{{ row.name }}</span>
                      <span style="float: right; color: #8492a6; font-size: 13px">#{{ row.id }}</span>
                    </el-option>
                  </el-select>
                </el-col>
                <el-col :span="6">
                  <el-input-number type="number"
                                   placeholder="兑换数量"
                                   v-model="item.num"
                                   :min="item.mustNeedCollected ? 1 : 0"></el-input-number>
                </el-col>
                <el-col :span="3">
<!--                  <el-switch :disabled="!canEdit"-->
<!--                             v-if="formData.type == 'ANY_TO_ONE'|| formData.type == 'SOME_TO_ANY'"-->
<!--                             v-model="item.mustNeedCollected"></el-switch>-->
                </el-col>
                <el-col :span="3">

                  <el-button
                             v-if="activities.length >=2"
                             @click.prevent="removeitem(item,activities)">删除</el-button>
                </el-col>
              </el-row>

            </el-form-item>
          </el-form>
          <div style="border-top:solid 1px #ccc;margin:10px;"></div>
          <el-form :model="{ awardList }"
                   ref="formlist"
                   label-position="right"
                   label-width="100px"
                   size="small">
            <el-form-item v-for="(item, index) in awardList"
                          :key="index"
                          :rules="[{ required: true, message: '请选择获得藏品', trigger: 'blur' }]"
                          :prop="`awardList[${index}].collectionId`"
                          :label="'获得藏品' + (index+1)">
              <el-row :gutter="20">
                <el-col :span="10">
                  <el-select v-model="item.collectionId"
                             filterable
                             :filter-method="filterCollection1"
                             style="width: 100%"
                             @change="changCollection(item,index,awardList)"
                            >
                    <el-option v-for="row in filterOptions1"
                               :label="row.name"
                               :value="row.id"
                               :key="row.id">
                      <span style="float: left">{{ row.name }}</span>
                      <span style="float: right; color: #8492a6; font-size: 13px">#{{ row.id }}</span>
                      <span style="float: right; color: #ee3333; font-size: 13px">《库存{{ row.stock }}》</span>
                    </el-option>
                  </el-select>
                </el-col>
                <el-col :span="6">
                  <el-input-number type="number"
                                   placeholder="发行数量"
                                   v-model="item.num"
                                   :min="1"></el-input-number>
                </el-col>
                <el-col :span="3">
                  <el-button
                             v-if="awardList.length >=2"
                             @click.prevent="removeitem(item,awardList)">删除</el-button>
                </el-col>
                <el-col :span="5">
                   <el-input type="number"
                        placeholder="概率"
                        @input="probabilityChange(index)"
                        v-model="item.probability"
                        :min="1"></el-input>
                </el-col>
              </el-row>
            </el-form-item>
          </el-form>

          <el-form-item class="form-submit">
            <el-button
                       @click="additem(activities)">增加兑换藏品</el-button>
            <el-button
                       @click="additem(awardList)">增加获得藏品</el-button>
              <el-button
                         v-show="formData.type == 'ANY_TO_ONE'"
                         @click="additem(activities, 1)">增加持有条件</el-button>
            <el-button @click="onSave"
                       :loading="saving"
                       type="primary"> 保存 </el-button>
            <el-button @click="onDelete"
                       :disabled="saving"
                       type="danger"
                       v-if="formData.id">
              删除
            </el-button>
            <el-button @click="$router.go(-1)"
                       :disabled="saving">取消</el-button>
          </el-form-item>
        </el-form>
      </div>
    </div>
  </div>
</template>
<script>
export default {
    name: 'ActivityCollectionEdit',
    created() {
        if (this.$route.query.id) {
            this.$http
                .get('activityCollection/get/' + this.$route.query.id)
                .then(res => {
                    console.log(res, 'res');
                    this.awardList = res.awardList;
                    this.activities = res.activities;
                    this.formData = res;
                })
                .catch(e => {
                    console.log(e);
                    this.$message.error(e.error);
                });
        }
        this.$http
            .post(
                '/collection/all',
                {
                    size: 10000,
                    sort: 'sort,desc;createdAt,desc',
                    query: { del: false, source: 'OFFICIAL', type: 'ALL' }
                },
                { body: 'json' }
            )
            .then(res => {
                this.options = res.content;
                this.filterOptions = this.options.filter(i => i.projectId == this.$store.state.projectId);
                this.filterOptions1 = this.options.filter(i => i.projectId == this.$store.state.projectId);
            });
    },
    data() {
        return {
            inputStatus:false,
            saving: false,
            activities: [
                {
                    collectionId: '',
                    collectionName: '',
                    mustNeedCollected: true,
                    num: 1
                }
            ],
            awardList: [
                {
                    collectionId: '',
                    collectionName: '',
                    num: 1,
                    probability:0
                }
            ],
            formData: {
                projectId: this.$store.state.projectId,
                type: 'ONE_TO_MANY',
                needCollectedNum: 1,
                compositetype: 0,
                decomposenum: 0
            },
            rules: {
                name: [
                    {
                        required: true,
                        message: '请输入名称',
                        trigger: 'blur'
                    }
                ],
                compositetype: [
                    {
                        required: true,
                        message: '请选择类型',
                        trigger: 'blur'
                    }
                ],
                pic: [
                    {
                        required: true,
                        message: '请上传图片',
                        trigger: 'blur'
                    }
                ],
                collectionId: [
                    {
                        required: true,
                        message: '请选择兑换藏品',
                        trigger: 'blur'
                    }
                ],
                num: [
                    {
                        required: true,
                        message: '请输入兑换数量',
                        trigger: 'blur'
                    }
                ],
                startTime: [
                    {
                        required: true,
                        message: '请输入活动开始时间',
                        trigger: 'change'
                    }
                ],
                endTime: [
                    {
                        required: true,
                        message: '请输入活动结束时间',
                        trigger: 'change'
                    }
                ],
                total: [
                    {
                        required: true,
                        message: '请输入发行数量',
                        trigger: 'blur'
                    }
                ],
                awardCollectionId: [
                    {
                        required: true,
                        message: '请选择获得藏品',
                        trigger: 'blur'
                    }
                ]
            },
            options:[],
            typeOptions: [
                {
                    value:  0,
                    label: '普通合成'
                } ,
                {
                    value: 1,
                    label: '缤纷合成'
                } ,
                {
                    value: 2,
                    label: '分解通道'
                } ,
                {
                    value: 3,
                    label: '置换通道'
                } ,
                {
                    value: 4,
                    label: '宝石兑换'
                } ,
                {
                    value: 5,
                    label: '联盟商城'
                }
            ],
            filterOptions: [],
            filterOptions1: []
        };
    },
    computed: {
        canEdit() {
            return !!!this.$route.query.id;
        }
    },
    methods: {
        changeType3(val) {
          this.inputStatus = false
          if(val == 3 || val == 2){
            this.inputStatus = true
          }
        },
        filterCollection(val) {
            this.filterOptions = this.options.filter(
                i => i.projectId == this.formData.projectId && (i => i.name.includes(val) || i.id == val)
            );
        },
        filterCollection1(val) {
            this.filterOptions1 = this.options.filter(
                i => i.projectId == this.formData.projectId && (i.name.includes(val) || i.id == val)
            );
        },
        changeProject(val) {
            this.filterOptions = this.options.filter(i => i.projectId == val);
            this.filterOptions1 = this.options.filter(i => i.projectId == val);
            //因为选择的是旧项目数据，所以清空选择的
            this.activities.forEach(item => {
                item.collectionId = '';
            });
            this.awardList.forEach(item => {
                item.collectionId = '';
            });
        },
        probabilityChange(index) {
          console.log(this.awardList[index].probability)
        },
        onSave() {
            console.log(this.awardList)
            let list = [];
            list.push(this.submitForm('form'), this.submitForm('formref'), this.submitForm('formlist'));
            Promise.all(list).then(() => {
                const data = {
                    activities: this.activities,
                    awardList: this.awardList,
                    ...this.formData
                };
                console.log(data, 'data');
                this.submit();
            });
        },
        submitForm(formName) {
            return new Promise((resolve, reject) => {
                this.$refs[formName].validate(valid => {
                    if (valid) {
                        resolve();
                    } else reject();
                });
            });
        },
        removeitem(item, that) {
            var index = that.indexOf(item);
            if (index !== -1) {
                that.splice(index, 1);
            }
        },
        additem(that, mustNeed) {
            if(mustNeed == 1) {
                that.push({
                    collectionId: '',
                    collectionName: '',
                    mustNeedCollected: false,
                    num: 1,
                    key: Date.now()
                });
            } else {
                that.push({
                    collectionId: '',
                    collectionName: '',
                    mustNeedCollected: true,
                    num: 1,
                    key: Date.now()
                });
            }

        },
        submit() {
            // let data = { ...this.formData };
            const data = {
                activities: this.activities,
                awardList: this.awardList,
                ...this.formData
            };
            let startTime = new Date(this.formData.startTime).getTime()
            let endTime = new Date(this.formData.endTime).getTime()
            if(startTime >= endTime) {
                this.$message.error('活动结束时间必须大于活动开始时间');
                return
            }
            this.saving = true;
            this.$http
                .post('/activityCollection/save', data, { body: 'json' })
                .then(res => {
                    this.saving = false;
                    this.$message.success('成功');
                    this.$router.go(-1);
                })
                .catch(e => {
                    console.log(e);
                    this.saving = false;
                    this.$message.error(e.error);
                });
        },
        onDelete() {
            this.$confirm('删除将无法恢复，确认要删除么？', '警告', { type: 'error' })
                .then(() => {
                    return this.$http.post(`/activityCollection/del/${this.formData.id}`);
                })
                .then(() => {
                    this.$message.success('删除成功');
                    this.$router.go(-1);
                })
                .catch(e => {
                    if (e !== 'cancel') {
                        console.log(e);
                        this.$message.error((e || {}).error || '删除失败');
                    }
                });
        },
        changAwardCollection(row) {
            let data = this.options.find(item => {
                return item.id === row;
            });
            this.formData.awardCollectionName = data.name;
            console.log(this.formData.awardCollectionName);
        },
        changCollection(row, index, that) {
            this.options.find(item => {
                if (item.id == row.collectionId) {
                    that[index].collectionName = item.name;
                    return item;
                }
            });
        }
    }
};
</script>
<style lang="less" scoped>
/deep/.el-input.is-disabled .el-input__inner {
    color: #7c7e7e;
}
/deep/.el-radio__input.is-disabled + span.el-radio__label {
    color: #7c7e7e;
}
</style>
